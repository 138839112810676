window.googletag = googletag || {};
googletag.cmd = googletag.cmd || [];
googletag.cmd.push(function() {
  var b = "";
  var e = "";
  if (document.referrer) {
    var d = new Array();
    d = document.referrer.split("/");
    e = d[2];
    if (e == location.host) {
      if (d.length > 4) {
        e = d[2] + "/" + d[3] + "/" + d[4]
      } else {
        if (d.length > 3) {
          e = d[2] + "/" + d[3]
        }
      }
    }
  }
  var c = new Array();
  c = location.pathname.split("/");
  var a = "";
  if (c.length > 2) {
    a = "/" + c[1] + "/" + c[2]
  } else {
    if (c.length > 1) {
      a = "/" + c[1]
    }
  }
  var f,g = "";
  f = location.pathname;
  g = c[(c.length - 1)];
  b = a + " " + e;
  b = b.split("=").join("_");
  googletag.pubads().setTargeting("us",b);
  googletag.pubads().setTargeting("us1",f);
  googletag.pubads().setTargeting("us2",g);
  googletag.pubads().enableSingleRequest();
  googletag.enableServices()
});
